/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

import ReactGA from "react-ga";

export const initialize = () => {
  ReactGA.initialize("UA-158614360-1", {
    gaAddress: require("../public/third-parties/analytics.js") as string,
  });
};

export const pageview = () => {
  ReactGA.set({
    dimension1: navigator.userAgent, // User Agent
    page: window.location.pathname,
    transport: "beacon",
  });

  ReactGA.plugin.require("displayfeatures");
  ReactGA.plugin.require("ecommerce");
  ReactGA.plugin.require("linkid");

  ReactGA.pageview(window.location.pathname);
};

export const event = (category: string, action: string) => {
  ReactGA.event({
    action,
    category,
  });
};

export const exception = (description: string, fatal: boolean = false) => {
  ReactGA.exception({
    description,
    fatal,
  });
};
