/*
 * Copyright (C) 2020-present Borza LLC. All rights reserved.
 */

export const toRelativePageURL = (path: string, market: string = "en-US",
                                  exportTrailingSlash: boolean = true) => (
  (market !== "en-US" ? "/" + market.toLowerCase() : "") + path +
    (!path.endsWith("/") && exportTrailingSlash ? "/" : "")
);

export const toAbsolutePageURL = (path: string, market: string = "en-US",
                                  exportTrailingSlash: boolean = true) => (
  "https://www.lastingsmiles.org" +
    toRelativePageURL(path, market, exportTrailingSlash)
);
